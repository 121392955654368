.back {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    width: 100%;
    font-size: 13.5px;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 35px;
    margin: 30px 0;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #818181;
    border: 2px solid #818181;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    cursor: pointer;
}