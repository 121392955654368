/* Importation d'une police Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', Arial, sans-serif; /* Police principale avec secours */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#1B344C;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.smaller {
	font-size: 12px;
}

.ldescription {
	color:  #f0efef;
	font-family: 'Roboto', Arial, sans-serif; /* Police principale avec secours */
}

.App{
	min-height: 100vh;
	background:#1B344C;
}

	  .logo {
		width: 130px;
	  }
	  
	img {
		width: 100%;
		height: 100%;
	}		

	nav{
		background-color: #C84C47; /* Couleur de fond de la navbar */
		overflow: hidden; /* Pour éviter que le contenu ne déborde */
		border-bottom: 2px solid #C84C47; /* Bordure inférieure blanche */
		color: white;
		font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
	}
  
    .App-Container {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		color: white;
		width: 100%;
    }
  
    .App-Container-Home {
	    height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		color: white;
		width: 100%;
    }
  
	.loader {
		height: 100vh;
		display: flex;
		text-align: center;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: calc(10px + 2vmin);
		color: white;
	}

  .Brand {
    font-weight: 900;
    margin: 20px 0;
	font-family: 'Poppins', sans-serif;
  }
  
  .desc {
	color: #414141;
    font-size: 19px;
    font-weight: 600;
    line-height: 23px;
  }

	.go {
		text-decoration: none;
		font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
		font-weight: bold;
		width: 100%;
		letter-spacing: 1px;
		display: inline-block;
		padding: 15px 35px;
		border-radius: 35px;
		transition: 0.3s;
		line-height: 1;
		color: #fff;
		border: 2px solid #C84C47;
		background: #C84C47;
		-webkit-animation-delay: 0.3s;
		animation-delay: 0.3s;
		cursor: pointer; 
	}

	.custom-alert {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		border-top: 1px solid #ddd;
		box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
		padding: 20px;
		z-index: 1000;

		/* Flexbox pour disposition en ligne */
		display: flex;
		flex-direction: row; /* Disposition en ligne (horizontale) */
		align-items: center; /* Centrer verticalement */
		justify-content: center; /* Centrer horizontalement */
		gap: 20px; /* Espacement entre les colonnes (boutons) */
	}

 .custom-alert button {
	  display: block;
	  width: 100%;
	  margin-bottom: 10px;
	  padding: 10px;
	  font-size: 18px;
	  color: #333;
	  background-color: #f8f9fa;
	  border: none;
	  border-radius: 5px;
	  cursor: pointer;
  }

  .custom-alert button:hover {
	  background-color: #e9ecef;
  }

  .custom-alert button:last-child {
	  color: red;
	  font-weight: bold;
  }
  
  
  .btn-logout {
    background: none; /* Pas de fond */
    border: none; /* Pas de bordure */
    padding: 0; /* Pas de padding */
    margin: 0; /* Pas de margin */
    color: #f2f2f2; /* Couleur du texte (icône) */
	font-size: 12px;
    cursor: pointer; /* Curseur de pointeur */
  }

  .box-features {
    overflow: hidden;
    background: #d18e8e;
	width: 100%;
    box-shadow: 2px 0 20px 0 rgba(79, 96, 143, 0.12);
    transition: all 0.3s ease-in-out;
    border-radius: 15px;
    z-index: 1;
    text-align: center;
    padding: 1%;
  }

  .img-room {
	border-top-left-radius: 15px;
	border-top-right-radius: 15px;
  }

  .div-details-welcome {
    text-align: left;
    padding: 5%;
    height: 40%;
    margin-bottom: 2%;
  }

  .details1 {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #1B344C;
    font-weight: 650;
	font-size: 17px;
  }

  .price {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 14px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 50%;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #1B344C;
    border: 2px solid #1B344C;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
  }

  .btn-start {
    text-decoration: none;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-weight: 600;
    font-size: 17px;
    text-transform: none;
    letter-spacing: 1px;
    display: inline-block;
    padding: 15px 40px;
    border-radius: 25px;
    transition: 0.3s;
    line-height: 1;
    color: #fff;
    background: #783031;
    border: 2px solid #783031;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
    text-decoration: none;
    cursor: pointer;
    margin-top: 5%;
    width: 100%;
  }

  .floating {
	position: fixed; /* Le bouton sera fixé par rapport à la fenêtre */
	bottom: 20px;    /* Décale le bouton du bas de la page */
	right: 20px;     /* Décale le bouton du côté droit */
	background-color: #fff; /* Fond blanc */
	color: #783031;  /* Couleur de l'icône */
	padding: 16px;   /* Espace autour de l'icône */
	border-radius: 50%; /* Forme circulaire */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre légère */
	font-size: 24px; /* Taille de l'icône */
	cursor: pointer; /* Coursor pour indiquer que c'est un bouton */
	transition: all 0.3s ease; /* Transition pour les effets au survol */
  }

